.emptySearch_container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 185px;
    height: 240px;
    margin: 40px auto 0;
    border-radius: 4px;
    background-color: #201e1c;
    /* box-shadow: 0px 4px 8px 0px #ffffff29; */
    box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.wrap {
    margin: 35px auto 0;
    width: 120px;
    height: 100px;
    position: relative;
  }

.emptySearch_container .txtResults {
    font-size: 1.8rem;
    font-weight: 700;
    /* text-transform: uppercase; */
    margin-top: 3em;
    color: #FFFF;
    height: 45px;
}

  div.items {
    font-size: 50px;
    color: rgb(243, 184, 104);
  }
  
  div.items .fa {
    position: absolute;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    opacity: 0;
  }


  div.items .fa:nth-child(1) {
    animation: slidefade 20s 0s infinite linear;
  }
  div.items .fa:nth-child(2) {
    animation: slidefade 20s 2s infinite linear;
  }
  div.items .fa:nth-child(3) {
    animation: slidefade 20s 4s infinite linear;
  }
  div.items .fa:nth-child(4) {
    animation: slidefade 20s 6s infinite linear;
  }
  div.items .fa:nth-child(5) {
    animation: slidefade 20s 8s infinite linear;
  }
  div.items .fa:nth-child(6) {
    animation: slidefade 20s 10s infinite linear;
  }
  div.items .fa:nth-child(7) {
    animation: slidefade 20s 12s infinite linear;
  }
  div.items .fa:nth-child(8) {
    animation: slidefade 20s 14s infinite linear;
  }
  div.items .fa:nth-child(9) {
    animation: slidefade 20s 16s infinite linear;
  }
  div.items .fa:nth-child(10) {
    animation: slidefade 20s 18s infinite linear;
  }
  

.wrap i.fa-search {
    color: #FFFF;
    position: absolute;
    z-index: 2;
}
  
i.search-1 {
    font-size: 20px;
    top: 5px;
    left: 10;
    top:-10px;
    left:-5px;
    animation: rotcw 5s infinite linear;
  }
  
i.search-2 {
    font-size: 12px;
    top: 10px;
    right: 20px;
    top: -5px;
    right: 5;
    animation: rotcw 4s infinite linear;
  }
  
i.search-3 {
    font-size: 12px;
    bottom: 0;
    left: 10px;
    bottom: -15px;
    left: -5px;
    animation: rotccw 4.4s infinite linear;
}
  
i.search-4 {
    font-size: 20px;
    bottom: -5px;
    right: 5;
    bottom: -20px;
    right: -10px;
    animation: rotccw 5.5s infinite linear;
  }

  @keyframes slidefade {
    0% {
      opacity: 0;
      transform: translate(50px, 0);
    }
    4.5% {
      opacity: 1;
      transform: translate(10px, 0);
    }
    10.5% {
      opacity: 1;
      transform: translate(-10px, 0);
    }
    15% {
      opacity: 0;
      transform: translate(-50px, 0);
    }
    100% {
      opacity: 0;
      transform: translate(50px, 0);
    }
  }

@keyframes rotcw {
    from {
      transform: rotate(0deg) translate(5px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translate(5px) rotate(-360deg);
    }
  }
  
  @keyframes rotccw {
    from {
      transform: rotate(0deg) translate(5px) rotate(0deg);
    }
    to {
      transform: rotate(-360deg) translate(5px) rotate(360deg);
    }
  }
  

