* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}