.mapContainer{
    /* display: flex;
    justify-content: center; */
    background-color: #282623;
    padding: 30px 0;
}

.mapContainer h3{
    text-align: center;
    font-size: 2rem;
    margin: 0 0 20px;
    color: #FFFF;
}

.mapContainer > div{
    width: fit-content;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    background-color: #201E1C;
    box-shadow: 0px 4px 8px 0px #00000029;
    
}