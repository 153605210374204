.footerContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 25vh;
    background-color: #f3b868ff;
}

.footerContainer div{
    padding: 0 50px;
    margin-bottom: 10px;
}

.footerContainer a{
    margin-left: 5px;
    font-size: 1.6rem;
    font-weight: 500;
    color: #FFFF;
    text-decoration: none;
}

.footerContainer a:hover{
    text-decoration: underline;
}

.footerContainer a:hover,
.footerContainer a:hover ~ .socialIcon{
    text-decoration: underline;
}