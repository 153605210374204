
.swiperContainer{
    background-color: #282623;
    border: none;
    padding: 20px 0 10px;
}

.section-skeleton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* height: 300px; */
}

.card-skeleton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    width: 165px;
    height: fit-content;
    border-radius: 4px;
    background-color: #201E1C;
    box-shadow: 0px 4px 8px 0px #00000029;
    color: rgb(148,148,148);
    /* overflow-y: hidden; esto genera problemas con el swiper*/
}

.list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

.productContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    width: 165px;
    height: fit-content;
    border-radius: 4px;
    background-color: #201E1C;
    box-shadow: 0px 4px 8px 0px #00000029;
    color: rgb(148,148,148);
    /* overflow-y: hidden; esto genera problemas con el swiper*/
}

.productContainer img{
    width: 145px;
    height: 200px;
}

.productContainer p{
    width: 100%;
    height: 35px;
    padding: 0 10px;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 300;
    overflow-y: hidden;
}

.novedades_title{
    font-size: 24px;
    margin: 0px 0px 20px 40px;
    color: #f3b868ff;
}

@media screen and (max-width: 450px){
    .novedades_title{
        font-size: 19px;
        margin: 0px 0px 20px 15px;
        color: #f3b868ff;
    }
  }