.list_results::marker{
 content: none;
}

.resultContainer{
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1.5fr 2fr;
    gap: 15px;
    width: 342px;
    /* height: 193px; */
    height: fit-content;
    padding: 10px;
    border-radius: 4px;
    background-color: #282623;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
    /* border: none; */
}

.resultText{
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    font-size: 1.3rem;
    text-align: left;
    color: #808080;
    overflow: hidden;
}

.resultText h3{
    color: #FFFF;
    font-size: 1.5rem;
    max-height: 55px;
    width: 100%;
    overflow-y: hidden;
}

.resultText .pAutor{
    margin-top: 8px;
}
.resultText .pEditorial{
    margin-top: 5px;
}   

.availableLabel{
    /* margin-top: 10px !important; */
    font-size: 1.5rem;
}

.imgResult{
    /* max-width: 145px; */
    max-width: 100%;
}

@media screen and (max-width: 450px){
    .resultContainer{
        grid-template-columns: 1.4fr 2fr;
        width: 90vw;
    }
  }

