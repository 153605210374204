.waitSearch_container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 185px;
    height: 240px;
    margin: 40px auto 0;
    /* border: 0.1rem solid #FFFF; */
    border-radius: 4px;
    background-color: #201e1c;
    /* background-color: #282623; */
    box-shadow: 0 0 0 1px #d9d9d9 inset;
    /* box-shadow: 0px 4px 8px 0px #00000029; */
    /* box-shadow: 0px 4px 8px 0px #ffffff29; */
}



.waitSearch_container h3{
    margin-top: 50px;
    color: #dee1e4;
    font-size: 1.8rem;
    font-weight: 700;
}


.waitSearch_container i {
    display: inline-block;
    width: 30px;
    height: 35px;
    margin: 16px;
    background-image: url('https://i.imgur.com/hPTDiVf.png');
    background-size: cover;
    background-position: center;
}

.waitSearch_container .container {
    position: relative;
    /* height: 240px; */
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.waitSearch_container .center {
    position: relative;
    top:-5px;
    left:0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: #fff;
    border: 8px solid #DEE1E4;
    overflow: hidden;
}

/* .waitSearch_container .container::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: rgba(222, 225, 228, 0.4);
} */

.waitSearch_container .wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.waitSearch_container span::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 6px;
    background-color: #DEE1E4;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.waitSearch_container span::after {
    content: "";
    position: absolute;
    left: 20px;
    top: 12px;
    width: 30px;
    height: 12px;
    background-color: #DEE1E4;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-radius: 0 4px 4px 0;
}

.waitSearch_container .box-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.waitSearch_container .box-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

/* animation */

@-webkit-keyframes rowup-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@keyframes rowup-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@-webkit-keyframes rowup-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@keyframes rowup-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@keyframes cloud-loop {
    0% {
        -webkit-transform: translate(0, 15px);
        transform: translate(0, 15px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-webkit-keyframes shadow-loop {
    0% {
        -webkit-transform: translate(0, -35px) scale(1.15, 0.25);
        transform: translate(0, -35px) scale(1.15, 0.25);
    }
    100% {
        -webkit-transform: translate(0, -35px) scale(1, 0.25);
        transform: translate(0, -35px) scale(1, 0.25);
    }
}

@keyframes shadow-loop {
    0% {
        -webkit-transform: translate(0, -35px) scale(1.15, 0.25);
        transform: translate(0, -35px) scale(1.15, 0.25);
    }
    100% {
        -webkit-transform: translate(0, -35px) scale(1, 0.25);
        transform: translate(0, -35px) scale(1, 0.25);
    }
}

.waitSearch_container .box-1 {
    -webkit-animation: 1s rowup-1 linear infinite normal;
            animation: 1s rowup-1 linear infinite normal;
}

.waitSearch_container .box-2 {
    -webkit-animation: 1s rowup-2 linear infinite normal;
            animation: 1s rowup-2 linear infinite normal;
}

.waitSearch_container .center {
    -webkit-animation-name: cloud-loop;
    animation-name: cloud-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.waitSearch_container span {
    position: relative;
    left: 50px;
    top: 30px;
    -webkit-animation-name: cloud-loop;
    animation-name: cloud-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.waitSearch_container .container::before {
    -webkit-animation-name: shadow-loop;
    animation-name: shadow-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.waitSearch_container .dr {
position: absolute;
  bottom: 16px; 
  right: 16px;
  width:100px;
}