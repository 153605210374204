.search-container{
  background-color: #282623;
  /* background-color: #201E1C; */
  padding: 20px 0 40px;
}

.search-bar{
  display: flex;
  padding: 40px 0 0 0;
  justify-content: center;
  width: 100%;
  background-color: #201e1c;
  height: 120px;
}

.search-bar input{
  width: 40%;
  height: 50px;
  padding: 10px;
  border: 0;
  border-radius: 0.375em 0 0 0.375em;
  line-height: 30px;
  color: rgba(68, 68, 68, 1);
  background-color: #FAF8F7;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset;
  font-size: 1.8rem;
}

.search-bar input::-webkit-search-cancel-button{
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background:
    linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#000 45%,#000 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
    linear-gradient(135deg, transparent 0%,transparent 43%,#000 45%,#000 55%,transparent 57%,transparent 100%);
}

.search-bar input:focus{
  outline: none;
}

.search-bar button{
  position: relative;
  width: 50px;
  height: 50px;
  border: 0;
  padding: 0.75em;
  background-color: #f3b868ff;
  border-radius: 0 0.375em 0.375em 0;
}

.search-bar button:active{
  background-color: #201E1C;
}


.resultsContainer{
  display: grid;
  /* margin-top: 40px; */
  grid-template-columns: repeat( auto-fit , minmax(350px, 1fr));
  justify-items: center;
  grid-auto-flow: row;
  row-gap: 20px;
  column-gap: 15px;
  /* background-color: red; */
}

.textResultsContainer{
  padding: 0 25px 20px;
}

.textResults{
  color: #d9d9d9;
  font-size: 1.7rem;
  font-weight: 200;
}

.textResultsContainer hr{
  margin: 8px 0;
  border: none;
  background-color: rgba(217, 217, 217, 0.3);
  height: 0.1px;
}

.TopicSection{
    background-color: #201E1C;
    padding: 35px 0;
  }

.TopicSection h2{
  /* tiene que retornar esta parte, margin: 0 0 30px; */
  margin: 0 auto 20px;
  padding: 0 20px;
  max-width: 700px;
  text-align: center;
  color: #FFFF;
  font-size: 2.6rem;
}
.cardsContainer{
  display: grid;
  grid-template-columns: repeat( auto-fit , minmax(320px, 1fr ));
  justify-items: center;
  row-gap: 20px;
  column-gap: 15px;
}

.topicsCard{
  padding: 10px 15px;
  width: 310px;
  height: 75px;
  border-radius: 4px;
  font-size: 1.6rem;
  color: #FFFF;
  background-color: #282623;
  font-weight: bold;
  box-shadow: 0px 4px 8px 0px #00000029;
}

.icon_config{
  display: block;
  margin-bottom: 5px;
  width: 23px;
  height: 23px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.modal {
  position: fixed;
  top: calc(100vh - 80px);
  right: 25px;
  z-index: 999;
  width: fit-content;
  background-color: transparent;
}

.modal_small {
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: 999;
  width: fit-content;
  background-color: transparent;
}


@media screen and (max-width: 640px){
  .low_footer h2{
    font-size: 2.0rem;
  }

  .search-bar input{
    width: 60%;
  }
  .resultContainer{
    width: 342px;
  }
}

@media screen and (max-width: 450px){
  .resultsContainer{
    grid-template-columns: repeat( auto-fit , minmax(320px, 1fr ));
    justify-items: center;
    grid-auto-flow: row;
  }
}