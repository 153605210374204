.banner{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-image: url('https://i.imgur.com/vEk5JVV.png');
    background-position: 50% 70%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .glass{
    position: relative;
    z-index: 0;
    width: 100px;
    height: 100px;
    background-color: linear-gradient(135deg, rgba(255,255,255, 0.1), rgba(255,255,255,0));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 50%;
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
  
  .logo_container{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
  }

  .logo_{
    width: 90px;
    cursor: pointer;
  }
  
  .glass::before {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 130%;
      height: 130%;
      box-sizing: border-box;
      margin-left: -15%;
      margin-top: -15%;
      border-radius: 50%;
      background-color: #e3e8eb;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    

.banner .header__button{
    position: absolute;
    bottom: -22px;
    width: 215px;
    height: 45px;
    padding: 12px 0px;
    font-size: 1.5rem;
    font-weight: 700;
    background-color: #FAF8F7;
    border: none;
    border-radius: 4px;
    text-align: center;
    color: #201E1C;
    box-shadow: 0px 3px 5px 0px #b8b8b8;
    text-decoration: none;
  }

.header__button span{
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    vertical-align: -1px;
    background-image: url('https://i.imgur.com/75MaUUU.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
  
  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes pulse-dot {
    0% {
      transform: scale(.97);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(.97);
    }
  }


  @media screen and (max-width: 640px){
    .glass {
        width: 70px;
        height: 70px;
    }

    .logo_container{
        width: 60px;
        height: 60px;
      }
    
      .logo_{
        width: 60px;
      }

      .banner .header__button{
        bottom: -19px;
        width: 195px;
        height: 38px;
        padding: 10px 0px;
        font-size: 1.3rem;
      }
}